import emailjs from 'emailjs-com';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Type from '../utils/@types/types';

const SET_MODAL_MSG: string = 'SET_MODAL_MSG';
const CLEAR_MODAL_MSG: string = 'CLEAR_MODAL_MSG';

export const setModalMsg: Type.ActionThunk<Type.EmailForm, null> = (data) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        const name: string = data!.name.charAt(0).toUpperCase() + data!.name.slice(1);
        const emailJSForm: Type.EmailJSForm = {
            user_name: name,
            user_email: data!.email,
            message_html: data!.msg,
        };

        try {
            await emailjs.send('default_service', 'portfolio', emailJSForm, 'user_svVzdsNvPIpH9UZdGsjUw');

            dispatch({
                type: SET_MODAL_MSG,
                payload: {
                    code: 200,
                    title: 'Message delivered successfully',
                    msg: `Thank you ${name} for you message, chat with you soon.`,
                },
            });
        } catch (error: any) {
            dispatch({
                type: SET_MODAL_MSG,
                payload: {
                    code: 500,
                    title: 'Unable to deliver your email',
                    msg: `Sorry ${name}`,
                },
            });
        }
    };
};

export const clearModalMsg: Type.ActionThunk<null, null> = () => ({
    type: CLEAR_MODAL_MSG,
});

const initialState: Type.ModalMsgState = {
    code: 0,
    title: '',
    msg: '',
};

const modalMsgReducer: Type.Reducer<Type.ModalMsgState, Type.ModalMsgAction> = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_MSG:
            return { ...action.payload };
        case CLEAR_MODAL_MSG:
            return { code: 0, title: '', msg: '' };
        default:
            return state;
    }
};

export default modalMsgReducer;
