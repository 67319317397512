import apolloLogo from '../../../assets/images/logos/languages/apollo.svg';
import awsCLILogo from '../../../assets/images/logos/languages/aws-cli.svg';
import awsCloudFormationLogo from '../../../assets/images/logos/languages/aws-cloudformation.svg';
import awsCloudWatchLogo from '../../../assets/images/logos/languages/aws-cloudwatch.svg';
import awsCodeBuildLogo from '../../../assets/images/logos/languages/aws-codebuild.svg';
import awsCodeDeployLogo from '../../../assets/images/logos/languages/aws-codedeploy.svg';
import awsCodepipelineogo from '../../../assets/images/logos/languages/aws-codepipeline.svg';
import awsEC2Logo from '../../../assets/images/logos/languages/aws-ec2.svg';
import awsLambdaLogo from '../../../assets/images/logos/languages/aws-lambda.svg';
import awsS3Logo from '../../../assets/images/logos/languages/aws-s3.svg';
import djangoLogo from '../../../assets/images/logos/languages/django.svg';
import figmaLogo from '../../../assets/images/logos/languages/figma.svg';
import jwtLogo from '../../../assets/images/logos/languages/jwt.svg';
import netlifyLogo from '../../../assets/images/logos/languages/netlify.svg';
import postmanLogo from '../../../assets/images/logos/languages/postman.svg';
import pythonLogo from '../../../assets/images/logos/languages/python.svg';
import seleniumLogo from '../../../assets/images/logos/languages/selenium.svg';
import styledComponentsLogo from '../../../assets/images/logos/languages/styled-components.png';

const logos = {
    arduino: <i className="devicon-arduino-plain colored svg-logo" />,
    'aws-cli': <img src={awsCLILogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-cloudformation': <img src={awsCloudFormationLogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-cloudwatch': <img src={awsCloudWatchLogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-codebuild': <img src={awsCodeBuildLogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-codedeploy': <img src={awsCodeDeployLogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-codepipeline': <img src={awsCodepipelineogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-ec2': <img src={awsEC2Logo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-lambda': <img src={awsLambdaLogo} alt="logo" className="svg-logo svg-logo--local" />,
    'aws-s3': <img src={awsS3Logo} alt="logo" className="svg-logo svg-logo--local" />,
    apollo: <img src={apolloLogo} alt="logo" className="svg-logo svg-logo--local" />,
    bash: <i className="devicon-bash-plain svg-logo" />,
    bootstrap: <i className="devicon-bootstrap-plain colored svg-logo" />,
    cplusplus: <i className="devicon-cplusplus-plain colored svg-logo" />,
    css: <i className="devicon-css3-plain colored svg-logo" />,
    coffeescript: <i className="devicon-coffeescript-original svg-logo" />,
    django: <img src={djangoLogo} alt="logo" className="svg-logo svg-logo--local" />,
    express: <i className="devicon-express-original svg-logo" />,
    figma: <img src={figmaLogo} alt="logo" className="svg-logo svg-logo--local" />,
    firebase: <i className="devicon-firebase-plain colored svg-logo" />,
    flask: <i className="devicon-flask-original colored svg-logo" />,
    git: <i className="devicon-git-plain colored svg-logo" />,
    github: <i className="devicon-github-plain svg-logo" />,
    graphql: <i className="devicon-graphql-plain colored svg-logo" />,
    heroku: <i className="devicon-heroku-original colored svg-logo" />,
    html: <i className="devicon-html5-plain colored svg-logo" />,
    javascript: <i className="devicon-javascript-plain colored svg-logo" style={{ background: 'black' }} />,
    jest: <i className="devicon-jest-plain colored svg-logo" />,
    jquery: <i className="devicon-jquery-plain colored svg-logo" />,
    jwt: <img src={jwtLogo} alt="logo" className="svg-logo svg-logo--local" />,
    materialui: <i className="devicon-materialui-plain colored svg-logo" />,
    mongodb: <i className="devicon-mongodb-plain colored svg-logo" />,
    netlify: <img src={netlifyLogo} alt="logo" className="svg-logo svg-logo--local" />,
    nodejs: <i className="devicon-nodejs-plain colored svg-logo" />,
    npm: <i className="devicon-npm-original-wordmark colored svg-logo" />,
    postgresql: <i className="devicon-postgresql-plain colored svg-logo" />,
    postman: <img src={postmanLogo} alt="logo" className="svg-logo svg-logo--local" />,
    python: <img src={pythonLogo} alt="logo" className="svg-logo svg-logo--local" />,
    react: <i className="devicon-react-original colored svg-logo" />,
    redux: <i className="devicon-redux-original colored svg-logo" />,
    ruby: <i className="devicon-ruby-plain colored svg-logo" />,
    rails: <i className="devicon-rails-plain colored svg-logo" />,
    sass: <i className="devicon-sass-original colored svg-logo" />,
    selenium: <img src={seleniumLogo} alt="logo" className="svg-logo svg-logo--local" />,
    socketio: <i className="devicon-socketio-original colored svg-logo" />,
    'styled-components': <img src={styledComponentsLogo} alt="logo" className="svg-logo svg-logo--local" />,
    typescript: <i className="devicon-typescript-plain colored svg-logo" />,
    webpack: <i className="devicon-webpack-plain colored svg-logo" />,
};

export default logos;
