import * as Type from '../utils/@types/types';

const SET_MODAL: string = 'SET_MODAL';
const CLEAR_MODAL: string = 'CLEAR_MODAL';

export const setModal: Type.ActionRedux<Type.Project> = (data) => ({
    type: SET_MODAL,
    payload: data,
});

export const clearModal: Type.ActionRedux<null> = () => ({
    type: CLEAR_MODAL,
});

const initialState: Type.Project = {
    name: '',
    imageUrl: '',
    alt: '',
    liveUrl: '',
    repoUrl: '',
    technologies: [],
    description: '',
};

const modalReducer: Type.Reducer<Type.ModalState, Type.ModalAction> = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL:
            return { ...action.payload };
        case CLEAR_MODAL:
            return initialState;
        default:
            return state;
    }
};

export default modalReducer;
