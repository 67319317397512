import { FC } from 'react';
import * as Type from '../utils/@types/types';

const SpanGenerator: FC<Type.SpanGeneratorC> = ({ keyBase, children }) => {
    return (
        <>
            {children!
                .toString()
                .split('')
                .map((char, idx) => {
                    return <span key={`${keyBase}-${idx}`}>{char}</span>;
                })}
        </>
    );
};

export default SpanGenerator;
