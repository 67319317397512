import { FC } from 'react';
import { Element } from 'react-scroll';
import ContactMeForm from '../components/ContactMeForm';
import SpanGenerator from '../components/SpanGenerator';

const ContactMe: FC = () => {
    return (
        <Element name="contact-me">
            <div className="section-contact-me">
                <div className="u-text-center">
                    <h2 className="heading-secondary heading-secondary__text-title-1">
                        <SpanGenerator keyBase="contact-me">CONTACT ME</SpanGenerator>
                    </h2>
                </div>
                <div className="u-text-center">
                    <ContactMeForm />
                </div>
            </div>
        </Element>
    );
};

export default ContactMe;
