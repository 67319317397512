import { FC, useEffect, useState } from 'react';
import * as Type from '../utils/@types/types';
import * as Request from '../utils/helpers/functions/request';
import Dots from './Dots';

const CERTIFICATES_URL: string = 'https://rogertakeshita-pub.s3.amazonaws.com/assets/data/certificates.json';

const Carousel: FC = () => {
    const [translateX, setTranslateX] = useState<number>(0);
    const [certIdx, setCertIdx] = useState<number>(0);
    const [certificates, setCertificates] = useState<Type.OnlineCertificate[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response: Type.Response<Type.CertificatesRes> = await Request.getData(CERTIFICATES_URL, false);
                setCertificates([...response.data.certificates]);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleClickLeft: Type.HandleClickFn = () => {
        if (translateX === 0) {
            setTranslateX(-100 * (certificates.length - 1));
            setCertIdx(certificates.length - 1);
        } else {
            setTranslateX((prev) => prev + 100);
            setCertIdx((prev) => prev - 1);
        }
    };

    const handleClickRight: Type.HandleClickFn = () => {
        if (translateX === -100 * (certificates.length - 1)) {
            setTranslateX(0);
            setCertIdx(0);
        } else {
            setTranslateX((prev) => prev - 100);
            setCertIdx((prev) => prev + 1);
        }
    };

    const handleDotClick: Type.HandleClickDataFn<number, null> = (_, idx) => {
        setTranslateX(-100 * +idx!);
        setCertIdx(+idx!);
    };

    if (certificates.length === 0) return <></>;

    return (
        <div className="carousel u-margin-xsmall u-display-inline-flex">
            {certificates.map((course, idx) => {
                return (
                    <div
                        key={`course-${idx}`}
                        className="carousel__slide"
                        style={{ transform: `translateX(${translateX}%)` }}
                    >
                        <img src={course.certificateUrl} alt={course.alt} className="carousel__img" />
                    </div>
                );
            })}
            <button className="btn-carousel btn-carousel--left" onClick={handleClickLeft}>
                &lt;
            </button>
            <button className="btn-carousel btn-carousel--right" onClick={handleClickRight}>
                &gt;
            </button>
            <Dots idx={certIdx} certificates={certificates} handleDotClick={handleDotClick} />
        </div>
    );
};

export default Carousel;
