import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Element } from 'react-scroll';
import Card from '../components/Card';
import SpanGenerator from '../components/SpanGenerator';
import { setModal } from '../redux/modal';
import * as Type from '../utils/@types/types';
import * as Request from '../utils/helpers/functions/request';

const PROJECTS_URL: string = 'https://rogertakeshita-pub.s3.amazonaws.com/assets/data/projects.json';

const Projects: FC = () => {
    const [projects, setProjects] = useState<Type.Project[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const response: Type.Response<Type.ProjectsRes> = await Request.getData(PROJECTS_URL, false);
                setProjects([...response.data.projects]);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleSelect: Type.HandleClickDataFn<Type.Project, null> = (e, data) => {
        e!.preventDefault();
        dispatch(setModal(data));
    };

    if (projects.length === 0) return <></>;

    return (
        <Element name="projects">
            <div className="section-projects">
                <div className="section-projects--bg">&nbsp;</div>
                <div className="section-projects--pulse">&nbsp;</div>
                <div className="u-text-center">
                    <h2 className="heading-secondary heading-secondary__text-title-1">
                        <SpanGenerator keyBase="projects">PROJECTS</SpanGenerator>
                    </h2>
                </div>
                <div className="projects">
                    <div className="row u-text-center u-items-center u-flex-wrap-wrap">
                        <div className="col-1-of-1">
                            {projects.map((project, idx) => {
                                return <Card key={`project-${idx}`} handleSelect={handleSelect} project={project} />;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    );
};

export default Projects;
