import { FC } from 'react';
import * as Type from '../utils/@types/types';
import logos from '../utils/helpers/logos/logos';

const SkillsLogos: FC<Type.SkillsLogosC> = ({ title, skills }) => {
    return (
        <div className="skills">
            <h3 className="heading-tertiary skills__skew-y--none">{title}</h3>
            <div className="skills__logos">
                {skills.map((skill) => {
                    return (
                        <div key={`skill-${skill.id}`} className="skills__logo-box skills__skew-y--none">
                            {/* @ts-ignore */}
                            {logos[skill.id]}
                            <p className="skills__paragraph">{skill.name}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SkillsLogos;
