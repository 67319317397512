import { FC, useEffect, useState } from 'react';
import { Events, Link, scrollSpy } from 'react-scroll';
import * as Type from '../utils/@types/types';

const Navbar: FC = () => {
    const [visible, setVisible] = useState(false);
    const [btnStatus, setBtnStatus] = useState(false);

    useEffect(() => {
        Events.scrollEvent.register('begin', (_, __) => {
            // console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', (_, __) => {
            // console.log('end', arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    const handleSetActive: Type.HandleActiveFn = (to) => {
        if (to === 'header-portfolio') {
            setVisible(false);
        } else {
            setVisible(true);
        }
    };

    const handleClick: Type.HandleClickFn = (e) => {
        e!.preventDefault();
        setBtnStatus((prevState) => !prevState);
    };

    const navbarClass: string = visible
        ? `navbar navbar--visible ${btnStatus ? 'navbar--column' : ''}`
        : `navbar ${btnStatus ? 'navbar--column' : ''}`;

    const navbarBtnClass: string = visible
        ? `navbar__btn ${btnStatus ? 'navbar__btn--open' : 'navbar__btn--close'}`
        : `navbar__btn navbar__btn--fade ${btnStatus ? 'navbar__btn--open' : 'navbar__btn--close'}`;

    const navbarListClass: string = `navbar__list ${btnStatus ? 'navbar__list--visible' : 'navbar__list--hidden'}`;

    return (
        <div className={navbarClass}>
            <Link
                className="navbar__logo"
                to="header-portfolio"
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
            >
                Roger Takeshita
            </Link>
            <ul className={navbarListClass}>
                <li className="navbar__item">
                    <Link
                        className="navbar__link"
                        activeClass="navbar--active"
                        to="about-me"
                        spy={true}
                        smooth={true}
                        offset={-66}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        About Me
                    </Link>
                </li>
                <li className="navbar__item">
                    <Link
                        className="navbar__link"
                        activeClass="navbar--active"
                        to="skills"
                        spy={true}
                        smooth={true}
                        offset={-65}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        Skills
                    </Link>
                </li>
                <li className="navbar__item">
                    <Link
                        className="navbar__link"
                        activeClass="navbar--active"
                        to="projects"
                        spy={true}
                        smooth={true}
                        offset={-65}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        Projects
                    </Link>
                </li>
                <li className="navbar__item">
                    <Link
                        className="navbar__link"
                        activeClass="navbar--active"
                        to="education"
                        spy={true}
                        smooth={true}
                        offset={-65}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        Education
                    </Link>
                </li>
                <li className="navbar__item">
                    <Link
                        className="navbar__link"
                        activeClass="navbar--active"
                        to="contact-me"
                        spy={true}
                        smooth={true}
                        offset={-65}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        Contact Me
                    </Link>
                </li>
            </ul>
            <div onClick={handleClick} className={navbarBtnClass}>
                <div className="navbar__btn__burger"></div>
            </div>
        </div>
    );
};

export default Navbar;
