import { FC } from 'react';
import * as Type from '../utils/@types/types';

const Card: FC<Type.CardC> = ({ handleSelect, project }) => {
    const handleClick: Type.HandleClickDataFn<string, Type.Project> = (e, type, project) => {
        const event: any = {};

        if (type === 'name') {
            e!.preventDefault();
            event.event = 'clickProjectName';
            event.projectName = project!.name;
            event.pageTitle = `${project!.name} - Info`;
            handleSelect(e!, project!);
        } else if (type === 'live-url') {
            event.event = 'clickProjectLiveURL';
            event.projectLiveURL = project!.liveUrl;
            event.projectName = project!.name;
            event.pageTitle = `${project!.name} - Live App`;
        } else if (type === 'repo-url') {
            event.event = 'clickProjectRepoURL';
            event.projectRepoURL = project!.repoUrl;
            event.projectName = project!.name;
            event.pageTitle = `${project!.name} - Repo`;
        }
        // @ts-ignore
        window.dataLayer && window.dataLayer.push(event);
    };

    return (
        <div className="card u-margin-xsmall">
            <div className="card__side card__side--front">
                <img loading="lazy" src={project.imageUrl} className="card__picture" alt={project.alt} />
                <div className="card__front-box u-display-inline-flex-end">
                    <h4 className="heading-quaternary heading-quaternary--bg-green u-padding-left-right-small">
                        <span className="card__heading-span">{project.name}</span>
                    </h4>
                </div>
            </div>
            <div className="card__side card__side--back">
                <div className="card__back-box">
                    <h3 className="heading-tertiary heading-tertiary--white">{project.name}</h3>
                    <a href="/" onClick={(e) => handleClick(e, 'name', project)} className="btn btn--white">
                        Project Info
                    </a>
                    <a
                        href={project.liveUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={(e) => handleClick(e, 'live-url', project)}
                        className="btn btn--white"
                    >
                        Live Project
                    </a>
                    <a
                        href={project.repoUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={(e) => handleClick(e, 'repo-url', project)}
                        className="btn btn--white"
                    >
                        GitHub
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Card;
