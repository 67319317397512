import { FC } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { clearModalMsg } from '../redux/modalMsg';
import * as Type from '../utils/@types/types';

const ModalMsg: FC = () => {
    const modalMsg = useSelector((state: RootStateOrAny) => state.modalMsg);
    const dispatch = useDispatch();

    const handleClick: Type.HandleClickFn = (e) => {
        e!.preventDefault();
        dispatch(clearModalMsg());
    };

    const modalMsgClass: string =
        modalMsg.title !== '' ? 'modal-msg modal-msg--visible-true' : 'modal-msg modal-msg--visible-false';

    const modalMsgStatus =
        modalMsg.code === 200 ? (
            <p className="modal-msg__msg">{modalMsg.msg}</p>
        ) : (
            <p className="modal-msg__msg">
                {modalMsg.msg}! Something went wrong, please send me a msg though my&nbsp;
                <a href="http://linkedin.com/in/roger-takeshita" target="_blank" rel="noopener noreferrer">
                    Linkedin
                </a>
            </p>
        );

    return (
        <div className={modalMsgClass}>
            <div className="modal-msg__header">
                <p className="modal-msg__title">{modalMsg.title}</p>
                <a href="/" onClick={handleClick} className="modal-msg__close">
                    &times;
                </a>
            </div>
            <div className="modal-msg__body">{modalMsgStatus}</div>
        </div>
    );
};

export default ModalMsg;
