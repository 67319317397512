import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { clearModal } from '../redux/modal';
import * as Type from '../utils/@types/types';
import logos from '../utils/helpers/logos/logos';

const Modal: FC = () => {
    const initialState: Type.Project = { name: '', imageUrl: '', alt: '', description: '', technologies: [] };
    const [project, setProject] = useState<Type.Project>(initialState);
    const modal = useSelector((state: RootStateOrAny) => state.modal);
    const [modalState, setModalState] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (modal.name) {
            setModalState(true);
            setProject(modal);
        } else {
            setModalState(false);
            setProject({ name: '', imageUrl: '', alt: '', description: '', technologies: [] });
        }
    }, [modal]);

    const handleClick: Type.HandleClickDataFn<string, Type.Project> = (e, type, project) => {
        const event: any = {};

        if (type === 'close') e!.preventDefault();
        if (type === 'live-url') {
            event.event = 'clickProjectLiveURL';
            event.projectLiveURL = project!.liveUrl;
            event.projectName = project!.name;
            event.pageTitle = `${project!.name} - Live App`;
            // @ts-ignore
            window.dataLayer && window.dataLayer.push(event);
        }

        dispatch(clearModal());
        setModalState(false);
    };

    return (
        <div className={`modal modal--${modalState}`} onClick={(e) => handleClick(e, 'close')}>
            <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                <div className="modal__left">
                    <a
                        href={project.liveUrl}
                        onClick={(e) => handleClick(e, 'live-url', project)}
                        className="modal__link"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={project.imageUrl} alt={`${project.name}`} className="modal__photo" loading="eager" />
                    </a>
                </div>
                <div className="modal__right">
                    <a href="/" onClick={(e) => handleClick(e, 'close')} className="modal__close">
                        &times;
                    </a>
                    <div className="modal__header">
                        <h2 className="heading-secondary heading-secondary__text-title-2 heading-secondary--black u-margin-bottom-xsmall">
                            {project.name}
                        </h2>
                    </div>
                    <div className="modal__text-box">
                        <p className="modal__text ">{project.description}</p>
                    </div>
                    <div className="modal__technologies-box">
                        {project.technologies.map((tech, idx) => {
                            const key: string = tech.toString();
                            return (
                                <div key={`technology-${idx}`} className="modal__logo-box">
                                    {/* @ts-ignore */}
                                    {logos[key]}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
