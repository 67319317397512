export const education = [
    {
        institution: 'General Assembly (Previous Bitmaker Labs)',
        course: 'Software Engineering Immersive Bootcamp',
        date: 'Nov 2019 - Feb 2020',
        location: 'Toronto-ON',
        url: 'https://generalassemb.ly/education/software-engineering-immersive/toronto',
        logoUri: 'https://rogertakeshita-pub.s3.amazonaws.com/assets/images/logos/education_ga.png',
        description: '',
    },
    {
        institution: 'Pontifical Catholic University of Paraná',
        course: 'Bachelor Degree in Mechatronics Engineering',
        date: 'Jan 2005 - Jan 2011',
        location: 'Brazil',
        url: 'https://www.pucpr.br/cursos-graduacao/engenharia-mecatronica/',
        logoUri: 'https://rogertakeshita-pub.s3.amazonaws.com/assets/images/logos/education_puc.png',
        description: '',
    },
];
