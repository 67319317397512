import { FC, useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import SkillsLogos from '../components/SkillsLogos';
import SpanGenerator from '../components/SpanGenerator';
import * as Type from '../utils/@types/types';
import * as Request from '../utils/helpers/functions/request';

const SKILLS_URL: string = 'https://rogertakeshita-pub.s3.amazonaws.com/assets/data/skills.json';

const Skills: FC = () => {
    const [strongSkills, setStrongSkills] = useState<Type.Skill[]>([]);
    const [workingSkills, setWorkingSkills] = useState<Type.Skill[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response: Type.Response<Type.SkillsRes> = await Request.getData(SKILLS_URL, false);
                setStrongSkills([...response.data.strongSkills]);
                setWorkingSkills([...response.data.workingSkills]);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <Element name="skills">
            <div className="section-skills">
                <div className="u-text-center">
                    <h2 className="heading-secondary heading-secondary__text-title-1">
                        <SpanGenerator keyBase="skills">SKILLS</SpanGenerator>
                    </h2>
                </div>
                <div className="row u-text-center skills__skew-y">
                    <div className="col-1-of-2">
                        <SkillsLogos title="Strong" skills={strongSkills} />
                    </div>
                    <div className="col-1-of-2 skills__skew-y--border-left">
                        <SkillsLogos title="Working Knowledge" skills={workingSkills} />
                    </div>
                </div>
            </div>
        </Element>
    );
};

export default Skills;
