import { FC } from 'react';
import * as Type from '../utils/@types/types';

const Dots: FC<Type.DotsC> = ({ idx, certificates, handleDotClick }) => {
    return (
        <div className={'dots'}>
            {certificates.map((_, idx2) => {
                return (
                    <span
                        key={`certificate-${idx2}`}
                        onClick={(e) => handleDotClick(e, idx2)}
                        className={idx === idx2 ? 'dots__dot dots__dot--active' : 'dots__dot'}
                    />
                );
            })}
        </div>
    );
};

export default Dots;
