import { FC } from 'react';
import * as Type from '../utils/@types/types';

const Certificate: FC<Type.CertificateC> = ({ course }) => {
    return (
        <div className="certificate u-margin-xsmall u-display-inline-flex">
            <a href={course.url.toString()} rel="noopener noreferrer" target="_blank" className="certificate__link">
                <img loading="lazy" src={course.logoUri.toString()} alt="logo" className="certificate__img" />
                <div className="certificate__text-box">
                    <h4 className="heading-quaternary heading-quaternary--black">{course.course}</h4>
                    <p className="certificate__paragraph">{course.institution}</p>
                    <p className="certificate__paragraph">{course.date}</p>
                    <p className="certificate__paragraph">{course.location}</p>
                </div>
            </a>
        </div>
    );
};

export default Certificate;
